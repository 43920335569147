<template>
    <div style="padding:.5em 1em;z-index: 99; top: 0;background:#1F9BCE" class="position-fixed w-100">
        <div class="row align-items-center" style="gap:10px 0">
            <div class="col-lg">
                <router-link to="/homepage" class="home-link">
                    <img src="@/static/logo.png" style="max-width:200px" />
                </router-link>
            </div>
            <div class="col-4 d-flex justify-content-between" style="gap:10px 20px;max-width: 500px;">
                <a href="/homepage#services" class="home-link">
                    <div class="text-white h4 mb-0">Services</div>
                </a>
                <a href="/homepage#news-section" class="home-link">
                    <div class="text-white h4 mb-0">News</div>
                </a>
                <a href="/homepage#about-us" class="home-link">
                    <div class="text-white h4 mb-0">About Us</div>
                </a>
            </div>
            <div class="col-lg d-flex justify-content-end"> 
                <ul class="nav navbar-nav align-items-center ms-auto"> 
                    <li class="nav-item dropdown dropdown-user" v-if="user">
                        <router-link class="nav-link dropdown-toggle dropdown-user-link d-flex align-items-center" id="dropdown-user" to="/profile">
                            <div class="user-nav d-flex flex-column text-white text-end" style="">
                                <div class="user-name fw-bolder" style="width:150px;text-wrap: wrap;">{{user.user_name}}</div>
                                <!-- <span class="user-status">superadmin</span> -->
                            </div>
                            <span class="avatar ms-3">
                                <img class="round bg-white" 
                                    :src="user.user_picture"
                                    style="object-fit: cover;border:2px solid #FFAD32" alt="avatar" height="50" width="50">
                                <span class="avatar-status-online"></span>
                            </span>
                        </router-link> 
                    </li>
                    <li v-else>
                        <router-link to="/login">
                            <div class="h5 fw-bolder text-white rounded-pill"
                                style="background-color: #005071;border: 1px solid #FFAD32;padding:.75em 2em">
                                Sign In
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store'

export default {
    computed: {
        routeName() {
            return this.$route.meta.title
        },
        routeTitle() {
            return this.$route.meta.title
        },
        user() {
            return store.state.auth.USER
        },
        country() {
            return this.user?.country
        }
    },
    computed: {
        user(){
            return store.state.auth.USER
        }
    },
    methods: {
    },
    data() {
        return {
            open: false,
        }
    }
}

</script>

<style scoped>
    .home-link {
        position: relative;
        display: inline-block;
        text-decoration: none;
    }

    .home-link::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px; /* Adjust height as needed */
        background-color: #FFAD32;
        transition: width 0.3s ease-in-out;
    }

    .home-link:hover::after {
        width: 100%;
    }
</style>