<template>
    <div class="bg-danger">

        <!-- </div>  -->
        <b-overlay id="overlay-background" :show="loading.isLoading" variant="light" opacity="0.8" blur="2px" rounded="sm"
            class="p-0 m-0">
            <div style="background: rgb(24, 114, 148) !important;" class="position-relative" v-if="$isMobile() || (!$isMobile() && routeName != 'HomeDesktop')">
                <div class="vw-100 vh-100 position-absolute bg-dark" style="opacity: .5;"></div>
                <div class="w-100 d-flex flex-column position-relative" style="z-index:999">
                    <div class="w-100 border h-100 mx-auto d-flex flex-column" :style="`max-width:600px;min-height:100vh;background:#1F9BCE`">
                        <TopNav v-if="layout == 'logged_in'" />
                        
                        <div :class="`flex-fill d-flex flex-column h-100 ${layout == 'logged_in' ? 'pb-5 mb-5' : ''}`">
                            <slot />
                        </div>

                        <div class="bg-danger position-relative ">
                            <div class="position-fixed w-100" style="bottom: 0;max-width:600px;z-index: 99999;">
                                <BottomNav v-if="layout == 'logged_in'" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else style="background: #1F9BCE !important;min-height: 100vh;" class="position-relative">
                <Navbar />
                <div :class="` `">
                    <slot />
                </div>
                <Footer />
            </div>
            <template #overlay>
                <img src="../../public/logo-color.png" alt="Logo" style="max-width:100px" />
            </template>
        </b-overlay>

        <img v-if="!$isMobile() && routeName != 'HomeDesktop'" src="@/static/dog-left-right.png" class="position-absolute w-100" style="top:0;max-width:200px" />
        <img v-if="!$isMobile() && routeName != 'HomeDesktop'" src="@/static/dog-right-left.png" class="position-absolute w-100" style="max-width:200px;right:0;bottom: 0;" />

    </div> 
</template>

<script>

import { BOverlay } from 'bootstrap-vue'
import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import Noty from 'noty'

import Cookies from 'universal-cookie'
const cookies = new Cookies()

import TopNav from './components/top-nav.vue'
import BottomNav from './components/bottom-nav.vue'
import Navbar from './components/nav-bar.vue'
import Footer from './components/footer.vue'

import Swal from 'sweetalert2'

import logo from '@/static/ico-42.png'

export default {
    components: {
        BOverlay,
        BottomNav, TopNav, Navbar, Footer
    },
    computed: {
        routeName() {
            return this.$route.meta.title
        },
        loading() {
            return store.state.loading;
        },
        errors() {
            return store.getters.getErrors
        },
        success() {
            return store.getters.getSuccess
        },
        layout() {
            return this.$route.meta.layout
        },
        collapsed() {
            return this.$route.meta.collapsed
        }
    },
    watch: {
        errors: function (val) {
            val.map((item, idx) => {
                if (!item.isDisplayed) {
                    let error_notice = ''
                    if (item.err?.response?.data && Object.values(item.err?.response?.data).length > 0)
                        error_notice = Object.values(item.err?.response?.data).map((d) => {
                            return d + '\n'
                        }).join('')
                    else
                        error_notice = item.err?.message

                    // this.$toast({
                    //     component: ToastificationContent,
                    //     props: {
                    //         title: 'Error!',
                    //         icon: 'BellIcon',
                    //         text: error_notice,
                    //         variant: 'danger',
                            
                    //     },
                    // })

                    Swal.fire({
                        html: `
                        <div class="d-flex w-100">
                            <div class="mx-auto d-flex rounded-circle" style="width:150px;height:150px">
                                <img src='${logo}' class="m-auto" style='width:120px;'>
                            </div>
                        </div>
                        <div class="text-danger">
                            ${error_notice}
                        </div>
                        `,
                    });
                    
                    store.dispatch('setDisplayedError', idx)
                }
            })
        },
        success: function (val) {
            val.map((item, idx) => {
                if (!item.isDisplayed) {
                    let success_notice = item.message
                    if(item.status == 'error'){
                        // this.$toast({
                        //     component: ToastificationContent,
                        //     props: {
                        //         title: 'Error!',
                        //         icon: 'BellIcon',
                        //         text: success_notice,
                        //         variant: 'danger',
                        //     },
                        // })
                        Swal.fire({
                            html: `
                            <div class="d-flex w-100">
                                <div class="mx-auto d-flex rounded-circle" style="width:150px;height:150px">
                                    <img src='${logo}' class="m-auto" style='width:120px;'>
                                </div>
                            </div>
                            <div class="text-danger">
                                ${success_notice}
                            </div>
                            `,
                        });
                    } else
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Success!',
                            icon: 'BellIcon',
                            text: success_notice,
                            variant: 'success',
                        },
                    })
                    store.dispatch('setDisplayedSuccess', idx)
                }
            })
        },
    },
    mounted() {

        if (!store.state.auth.USER && cookies.get('token')) {
            store.dispatch('auth/GetUserData')
        }

        $.getJSON('https://api.db-ip.com/v2/free/self', function (data) {
            cookies.set('ip_addr', data.ipAddress, { path: '/' })
        });
    }
};
</script> 

<style>
    .Vue-Toastification__container.top-right {
        display: flex;
        margin-top: 1em;
        align-items: center;
    }

</style>