import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

import auth from './auth/index' 
import pet from './stores/pet' 
import chat from './stores/chat' 
import trainer from './stores/trainer' 
import training from './stores/training' 
import activities from './stores/activities' 
import walker from './stores/walker' 
import shelter from './stores/shelter' 
import store_api from './stores/store_api' 
import banners from './stores/banners' 
import vet from './stores/vet' 

import utilities from './utilities/index'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { 
    ...utilities, 
    app, appConfig, verticalMenu,
    auth, pet, chat, trainer, training,
    activities, walker, shelter, store_api,
    banners,vet
  },
  strict: process.env.DEV,
})
