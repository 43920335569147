import {withCredentials, withoutCredentials} from '@/static/baseURL'
import Cookies from 'universal-cookie'  
import router from "../../router";

const cookies = new Cookies()

const state = {
    SHELTER: null, 
    SHELTER_LIST: [], 
};

const mutations = {  
    UPD_SHELTER(state, data) { 
        state.SHELTER = {...data}
    },   
    UPD_SHELTER_LIST(state, data) { 
        state.SHELTER_LIST = {...data}
    },   
}; 

const actions = { 
    SetupShelter({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('shelter', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    commit('SUCCESS', {message:'Success To Register Shelter'}, { root: true })
                    router.push('/bussiness')
                    resolve()
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    }, 
    GetShelter({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().get('shelter/'+payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('UPD_SHELTER', res.data);   
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    GetShelterList({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withCredentials().post('shelter/list', {
                    limit: 99999,
                    token: cookies.get('token')
                }).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('UPD_SHELTER_LIST', res.data);   
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    SaveGallery({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('shelter/gallery', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    resolve()
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    }, 
    DeleteGallery({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().delete('shelter/gallery/'+payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    resolve()
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    }, 
    ApplyVolunteer({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('shelter/volunteer/apply', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    commit('SUCCESS', {message:'Success To Register Volunteer'}, { root: true })
                    resolve()
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    }, 
    DeleteVolunteer({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().delete('shelter/volunteer/'+payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    resolve()
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    }, 
    SaveVolunteer({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('shelter/volunteer', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    resolve()
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    }, 
    GetVolunteer({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().get('shelter/volunteer/'+payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    ApplyDonation({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('shelter/donation/apply', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    // commit('SUCCESS', {message:'Success To Register Volunteer'}, { root: true })
                    resolve()
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    }, 
    DeleteDonation({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().delete('shelter/donation/'+payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    resolve()
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    }, 
    SaveDonation({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('shelter/donation', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    resolve()
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    }, 
    GetDonation({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().get('shelter/donation/'+payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    ApplyOnlineParent({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('shelter/adoption/online-parent', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    // commit('SUCCESS', {message:'Success To Register Volunteer'}, { root: true })
                    resolve()
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    }, 
    DeleteAdoption({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().delete('shelter/adoption/'+payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    resolve()
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    }, 
    SaveAdoption({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('shelter/adoption', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    resolve()
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    }, 
    GetAdoption({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().get('shelter/adoption/'+payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    RequestAdoption({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('shelter/adoption/request-adoption', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    resolve(res.data)
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    }, 
    GetDetailAdoption({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('shelter/adoption/detail/'+payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    resolve(res.data)
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    }, 
}

export default {
    namespaced: true,
    state,
    mutations,
    actions, 
}