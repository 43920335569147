import {withCredentials, withoutCredentials} from '@/static/baseURL'
import Cookies from 'universal-cookie'  
import router from "../../router";

const cookies = new Cookies()

const state = {
    PET_LIST: [],  

    WALKER_INFO: null,  
    WALKER_DETAIL: null,
    WALKER_LIST: [],
    SCHEDULE_LIST: [],
    SCHEDULE_INFO: [],
};

const mutations = {   
    UPD_WALKER_DETAIL(state, data) { 
        state.WALKER_DETAIL = data
    },     
    UPD_WALKER_LIST(state, data) { 
        state.WALKER_LIST = data
    },     
    UPD_SCHEDULE_LIST(state, data) { 
        state.SCHEDULE_LIST = data
    },     
    UPD_SCHEDULE_INFO(state, data) { 
        state.SCHEDULE_INFO = data
    },     

    UPD_PET_LIST(state, data) { 
        state.PET_LIST = data
    },     
    UPD_WALKER_INFO(state, data) { 
        state.WALKER_INFO = data
    },     
}; 

const actions = {  
    WalkerSave({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('/walker', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    commit('SUCCESS', {message:'Success To Register Walker'}, { root: true })
                    router.push('/bussiness')
                    resolve()
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    }, 
    GetWalker({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().get('walker/data/'+payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('UPD_WALKER_DETAIL', res.data);   
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    GetWalkerList({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                payload = {
                    params: {
                        ...payload, 
                        token: cookies.get('token')
                    }
                }
                await withCredentials().get('/walker/data', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('UPD_WALKER_LIST', res.data);   
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    SaveSchedule({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('walker/data/schedule', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('SUCCESS', {message:'Success to updated data!'}, { root: true })
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    GetScheduleList({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withCredentials().post('walker/data/list/schedule', {
                    limit: 99999, ...payload,
                    token: cookies.get('token')
                }).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('UPD_SCHEDULE_LIST', res.data);   
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    }, 
    GetScheduleDetail({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('/walker/data/detail/schedule/'+payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('UPD_SCHEDULE_INFO', res.data);   
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },

    // OLD FUNCTIONS
    GetPets({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().get('/walker').then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('UPD_PET_LIST', res.data);   
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    SetWalkerAvailability({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('walker/save', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    // commit('SUCCESS', {message:'Success to updated data!'}, { root: true })
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    AddNote({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('walker/note', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('SUCCESS', {message:'Success to updated data!'}, { root: true })
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    DeleteNote({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().delete('walker/note/'+payload,).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('SUCCESS', {message:'Success to delete data!'}, { root: true })
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    GetSchedule({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().get('/walker/schedule/'+payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('UPD_WALKER_INFO', res.data);   
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    AddSchedule({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('walker/schedule', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('SUCCESS', {message:'Success to updated data!'}, { root: true })
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    DeleteSchedule({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().delete('walker/schedule/'+payload,).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('SUCCESS', {message:'Success to delete data!'}, { root: true })
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    GetInvoice({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().get('/walker/book/'+payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    // commit('UPD_WALKER_INFO', res.data);   
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    BookSchedule({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('walker/book', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    // commit('SUCCESS', {message:'Success to updated data!'}, { root: true })
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions, 
}