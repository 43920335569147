import {withCredentials, withoutCredentials} from '@/static/baseURL'
import Cookies from 'universal-cookie'  
import router from "../../router";

const cookies = new Cookies()

const state = {
    MY_ACTIVITIES: [], 
    PET_ACTIVITIES: [], 
};

const mutations = {   
    UPD_MY_ACTIVITIES(state, data) { 
        state.MY_ACTIVITIES = data
    },   
    UPD_PET_ACTIVITIES(state, data) { 
        state.PET_ACTIVITIES = data
    },   
}; 

const actions = {  
    GetMyActivities({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('my-activities', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('UPD_MY_ACTIVITIES', res.data);   
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    }, 
    GetPetActivities({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('pet-activities', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('UPD_PET_ACTIVITIES', res.data);   
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    }, 
}

export default {
    namespaced: true,
    state,
    mutations,
    actions, 
}