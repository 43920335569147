import {withCredentials, withoutCredentials} from '@/static/baseURL'
import Cookies from 'universal-cookie'  
import router from "../../router";

const cookies = new Cookies()

const state = {
    PET_INFO: null, 
    PET: null, 
};

const mutations = {   
    UPD_PET(state, data) { 
        state.PET = [...data]
    },  
    UPD_PET_INFO(state, data) { 
        state.PET_INFO = {...data}
    },  
}; 

const actions = { 
    GetPetList({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('pets/list', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('UPD_PET', res.data);   
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    SavePet({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('pets', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    if(res.data.status == 'error'){
                        commit('SUCCESS', {message:res.data.message, status:'error'}, { root: true })
                        reject()
                    } else {
                        commit('SUCCESS', {message:'Success To Update Data'}, { root: true })
                        // router.push('/profile') 
                        resolve(res.data) 
                    }
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    GetPet({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().get('pets/'+payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('UPD_PET_INFO', res.data);   
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    DeletePet({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().delete('pets/'+payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('SUCCESS', {message:'Success to delete data!'}, { root: true })
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions, 
}