import {withCredentials, withoutCredentials} from '@/static/baseURL'
import Cookies from 'universal-cookie'  
import router from "../../router";

const cookies = new Cookies()

const state = {
    TRAINING_LIST: [], 
    TRAINING: null,
    SCHEDULE: null,
};

const mutations = {   
    UPD_TRAINING_LIST(state, data) { 
        state.TRAINING_LIST = data
    },   
    UPD_TRAINING(state, data) { 
        state.TRAINING = data
    },   
    UPD_SCHEDULE(state, data) { 
        state.SCHEDULE = data
    },   
}; 

const actions = {  
    GetTrainingList({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('training/list', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('UPD_TRAINING_LIST', res.data);   
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    SaveTraining({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('training', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    commit('SUCCESS', {message:'Success Save Data'}, { root: true })
                    resolve(res.data.data)
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    GetTraining({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().get('training/'+payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('UPD_TRAINING', res.data);   
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    GetSchedule({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().get('training/schedule/'+payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('UPD_SCHEDULE', res.data);   
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    SaveSchedule({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('training/schedule', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    commit('SUCCESS', {message:'Success Save Data'}, { root: true })
                    resolve()
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    DeleteSchedule({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().delete('training/schedule/'+payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    commit('SUCCESS', {message:'Success To Delete Data'}, { root: true })
                    resolve()
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    GetInvoice({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().get('training/book/invoice/'+payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    BookTraining({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('training/book', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    commit('SUCCESS', {message:'Success Save Data'}, { root: true })
                    resolve(res.data)
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions, 
}