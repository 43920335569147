import {withCredentials, withoutCredentials} from '@/static/baseURL'
import Cookies from 'universal-cookie'  
import router from "../../router";

const cookies = new Cookies()

const state = {
    BANNERS: [],  
    ADS: [],  
};

const mutations = {   
    UPD_BANNERS(state, data) { 
        state.BANNERS = data
    },  
    UPD_ADS(state, data) { 
        state.ADS = data
    },  
}; 

const actions = {  
    GetBanners({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().get('banners', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('UPD_BANNERS', res.data);   
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },  
    GetAds({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().get('ads', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('UPD_ADS', res.data);   
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },  
}

export default {
    namespaced: true,
    state,
    mutations,
    actions, 
}