const state = { 
    success: []
};

const mutations = { 
    SUCCESS(state, data) { 
        state.success = [{...data, isDisplayed : false}]
    }, 
    SUCCESS_DISPLAYED(state, index) {
        state.success[index].isDisplayed = true
    }
};

const getters = {
    getSuccess(state){
        let success = state.success; 
        return success;
    }
}

const actions = {
    setDisplayedSuccess({commit}, index){
        commit('SUCCESS_DISPLAYED', index)
    }
}

export default { 
    state, 
    getters,
    mutations, actions
}