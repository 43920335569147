import axios from "axios"; 
import Cookies from 'universal-cookie'  
const cookies = new Cookies()

let token = cookies.get('token')
let instance = axios.create({baseURL: process.env.VUE_APP_URL})
instance.defaults.headers.common['ip_addr'] = cookies.get('ip_addr') 

export function withCredentials(){ 
    // instance.defaults.headers.common['authorization'] = `Bearer ${token}`
    instance.defaults.headers.common['authorization'] = token
    return instance
}

export function withoutCredentials(){ 
    return instance
}