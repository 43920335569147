import baseURL from '@/static/baseURL'
import Cookies from 'universal-cookie'  
const cookies = new Cookies()

const state = {
    DT: [],
    CURRENT: 'EN',
    error: null,
    isLoading: false
};

const mutations = {
    UPD_DT(state, data) {
        state.DT = data
    },
    UPD_CURRENT(state, data) {
        state.CURRENT = data 
    }, 
}; 

const actions = {
    changeLanguage({commit}, lang) {
        // commit('TOGGLE_LOADING', null, { root: true })
        cookies.set('lang', lang, { path: '/' })
        commit('UPD_CURRENT', lang)
    },
    getAllLang({commit}) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true })
            try { 
                const data = [
                    {
                        "lang_id": 1,
                        "lang_name": "Indonesia",
                        "lang_code": "IND",
                        "lang_img": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9f/Flag_of_Indonesia.svg/255px-Flag_of_Indonesia.svg.png"
                    },
                    {
                        "lang_id": 2,
                        "lang_name": "English",
                        "lang_code": "EN",
                        "lang_img": "https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/1200px-Flag_of_the_United_Kingdom.svg.png"
                    }
                ];
                commit('UPD_DT', data);
                commit('TOGGLE_LOADING', null, { root: true })
                resolve(data)
            } catch(err){
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', { message: 'Error di bahasa!' }, { root: true })
                reject()
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions, 
}