import {withCredentials, withoutCredentials} from '@/static/baseURL'
import Cookies from 'universal-cookie'  
import router from "../../router";

const cookies = new Cookies()

const state = {
    USER: null, 
};

const mutations = {  
    LOGOUT_USER(state) {
        state.USER = null
    },
    UPD_USER(state, data) { 
        state.USER = {...data}
    },  
}; 

const actions = {
    Login({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('users/login', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    if(res.data?.status == 'email_not_verified'){
                        commit('SUCCESS', {message:res.data?.message, status: 'error'}, { root: true })
                        reject()
                    } else {
                        if(res.data?.status == 'error'){
                            commit('SUCCESS', {message:'Wrong Email or Password!', status: 'error'}, { root: true })
                            reject()
                        } else {
                            commit('UPD_USER', res.data);  
                            cookies.set('token', res.data?.user_id)
                            cookies.set('country', res.data?.country)
                            
                            if(res.data?.user_role == 'service_provider'){
                                router.push({ name: 'bussiness' });
                            } else {
                                router.push({ name: 'homepage' });
                            }
    
                            resolve()
                        }
                    }
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    GetUserData({commit}) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                let uid = cookies.get('token')
                withoutCredentials().get('users/'+uid).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    
                    commit('UPD_USER', res.data);  
                    cookies.set('country', res.data?.country)

                    if(res.data) resolve(res.data)
                    else reject()
                }).catch(() => {
                    cookies.remove('token');  
                    router.push({ name: 'login' });
                    
                    commit('TOGGLE_LOADING', null, { root: true })
                    commit('ERROR', {err}, { root: true })
                    reject()
                })
            } catch(err){   
                cookies.remove('token');  
                router.push({ name: 'login' });
                
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    SaveUser({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('users', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    if(res.data.status == 'error'){
                        commit('SUCCESS', {message:res.data.message, status:'error'}, { root: true })
                        reject()
                    } else {
                        commit('SUCCESS', {message:'Success To Update Data'}, { root: true })
                        resolve()
                    }
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    Logout({commit}) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                commit('TOGGLE_LOADING', null, { root: true })
                commit('LOGOUT_USER');
                cookies.remove('token', { path: '/' })
                cookies.remove('country', { path: '/' })
                router.push('/login');
                resolve()
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    }, 
}

export default {
    namespaced: true,
    state,
    mutations,
    actions, 
}