<template>
    <div>
        <div>
            <div class="footer" style="background: #291B04;">
                <div class="container-lg py-3">

                    <div class="row align-items-center" style="gap:10px 0">
                        <div class="col-lg-3 text-white">
                            <img src="@/static/logo.png" class="w-100"  />
                            <div class="mt-1 fw-bolder">
                                Pet Goes Social
                            </div>
                            <div class="my-1 fw-bold">
                                We are the pet platform created for pet lovers. Whether you own a pet, love to extend your service for pets and owner
                                or simply you have a big heart that want to help volunteer with pet and community shelters. We are collectively curated
                                all services in our platform for you.
                            </div>
                            <div class="fw-bold">
                                +1 1234-4567-23 <br/><br/>
                                Location Nameles st, Block Unknown, Singapore. <br/><br/>
                                mongrela.org@gmail.com
                            </div>
                        </div>
                        <div class="col-lg-2" style="border-right: 1px solid white;border-left: 1px solid white">
                            <div class="d-flex flex-column" style="gap:10px">
                                <a href="" class="text-white fw-bold">About Us</a>
                                <a href="" class="text-white fw-bold">Services</a> 
                            </div>
                        </div>
                        <div class="col-lg">
                            <div class="mb-1 d-flex flex-wrap justify-content-between" style="gap:10px">
                                <h2 class="text-white fw-bolder h5 mb-0">Contact us here</h2>
                                <h2 class="text-white fw-bolder h5 mb-0">We will get back to you within 24 hours</h2>
                            </div>

                            <form id="form-footer">
                                <div class="row" style="gap:10px 0">
                                    <div class="col-lg-12">
                                        <input class="form-control" placeholder="Name" id="footer_name" v-model="name" required style="background-color: transparent;border: 2px solid #187294;color: white;" />
                                    </div>
                                    <div class="col-lg-12">
                                        <textarea class="form-control" id="footer_desc" v-model="desc" required style="background-color: transparent;border: 2px solid #187294;color: white;"
                                            placeholder="Ask your question and send it right away to our inbox"
                                            rows="3"></textarea>
                                    </div>
                                </div>
                            </form>
                            <div class="d-flex">
                                <button @click="submit_contact_footer()" style="background: #187294;" class="btn text-white mt-1 ms-auto">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center text-white fw-bolder py-1" style="padding: .5em 1em;background: #1D1203;">
                Copyright Mongrela, 2024
            </div>
        </div>
    </div>
</template>

<script>

export default {
    methods: {
        submit_contact_footer(){
            let subject = `Mongrela, Contact from ${this.name}`
            window.open(`mailto:mongrela.org@gmail.com?subject=${subject}&body=${this.desc}`)
        }
    },
    data() {
        return {
            name: '', desc: ''
        }
    }
}

</script>