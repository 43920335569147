import {withCredentials, withoutCredentials} from '@/static/baseURL'
import Cookies from 'universal-cookie'  
import router from "../../router";

const cookies = new Cookies()

const state = {
    TRAINER_LIST: null, 
    TRAINER: null, 
};

const mutations = {   
    UPD_TRAINER_LIST(state, data) { 
        state.TRAINER_LIST = {...data}
    },   
    UPD_TRAINER(state, data) { 
        state.TRAINER = {...data}
    },   
}; 

const actions = { 
    BecameATrainer({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('users/register-as-trainer', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    commit('SUCCESS', {message:'Success To Register as Trainer'}, { root: true })
                    router.push('/bussiness')
                    resolve()
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    GetTrainerList({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withCredentials().post('users/trainer-list', {...payload, token: cookies.get('token')}).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('UPD_TRAINER_LIST', res.data);   
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    GetTrainer({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().get('users/trainer/'+payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('UPD_TRAINER', res.data);   
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions, 
}