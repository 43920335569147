import {withCredentials, withoutCredentials} from '@/static/baseURL'
import Cookies from 'universal-cookie'  
import router from "../../router";

const cookies = new Cookies()

const state = {
    PRODUCT_LIST: [],
    STORE_LIST: []
};

const mutations = {  
    UPD_PRODUCT_LIST(state, data) { 
        state.PRODUCT_LIST = {...data}
    },   
    UPD_STORE_LIST(state, data) { 
        state.STORE_LIST = {...data}
    },   
}; 

const actions = { 
    GetStoreList({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                let config = {
                    params: payload,
                    limit: 9999,
                    token: cookies.get('token')
                }
                await withCredentials().post('store/list', {...config, token: cookies.get('token')}).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('UPD_STORE_LIST', res.data);   
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    SetupStore({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('store', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    commit('SUCCESS', {message:'Success To Register Store'}, { root: true })
                    router.push('/bussiness')
                    resolve()
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },  
    GetProductList({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                let config = {
                    params: payload,
                    limit: 9999,
                    token: cookies.get('token')
                }
                await withCredentials().post('products/list', {...config, token: cookies.get('token')}).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    commit('UPD_PRODUCT_LIST', res.data);   
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    SaveProduct({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().post('products', payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    commit('SUCCESS', {message:'Success Save Data'}, { root: true })
                    resolve()
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    DeleteProduct({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().delete('products/'+payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true })
                    commit('SUCCESS', {message:'Success To Delete Data'}, { root: true })
                    resolve()
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
    GetProduct({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true }) 
            try { 
                await withoutCredentials().get('products/'+payload).then(res => {
                    commit('TOGGLE_LOADING', null, { root: true }) 
                    resolve(res.data) 
                })
            } catch(err){  
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', {err}, { root: true })
                reject()
            }
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions, 
}