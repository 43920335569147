<template>
    <div style="background-color: #1B628C;" class="p-1">
        <div class="row align-items-center" style="gap:10px 0">
            <div class="col-8" v-if="routeName == 'Home'">
                <img src="@/static/logo.png" style="max-width:160px" />
            </div>
            <div v-else class="col-4">
                <h2 class="h1 mb-0 font-weight-bolder text-white">{{ routeTitle }}</h2>
            </div>
            <div class="col-4 d-flex justify-content-center" v-if="routeName != 'Home'">
                <router-link to="/homepage">
                    <img src="@/static/white-logo.png" style="max-width:50px" />
                </router-link>
            </div>
            <div class="col-4 text-right">
                <i class="bx bx-menu text-white" @click="toggle" style="font-size: 26px;"></i>
            </div>
        </div>

        <Drawer @close="toggle" align="right" :closeable="false">
            <div v-if="open" style="width:140px">
                <div class="text-primary d-flex position-absolute" style="top:10px;right:10px" @click="toggle">
                    <i class="bx bx-x font-weight-bolder" style="font-size:30px"></i>
                </div>          

                <div class="mt-2">
                    <h4 class="text-primary">Default Role</h4> 
                    <div class="d-flex flex-column mt-1" style="gap:0px">
                        <div :class="`d-flex align-items-center ${user.user_role == item.slug ? 'bg-primary' : ''}`" style="gap:10px;padding:5px 10px" @click="changeRole(item.slug)" v-for="item in roles">
                            <i :class="item.icon" style="font-size: 18px;"></i> 
                            <span>{{ item.name }}</span>
                        </div> 
                    </div>
                </div>

                <div class="mt-2">
                    <h4 class="text-primary">Country</h4> 
                    <div class="d-flex flex-column mt-1" style="gap:0px">
                        <div :class="`d-flex align-items-center ${country == item.slug ? 'bg-primary' : ''}`" style="gap:10px;padding:5px 10px" @click="changeRegion(item.slug)" v-for="item in countries">
                            <img :src="item.flag" 
                                style="height:15px;width:20px;object-fit: cover;" />
                            <span>{{ item.name }}</span>
                        </div> 
                    </div>
                </div>
            </div>
        </Drawer>
    </div>
</template>

<script>
import Drawer from "vue-simple-drawer"
import Cookies from 'universal-cookie'
import store from '@/store'

const cookies = new Cookies()

export default {
    components: {
        Drawer
    },
    computed: {
        routeName() {
            return this.$route.meta.title
        },
        routeTitle() {
            return this.$route.meta.title
        },
        user(){
            return store.state.auth.USER
        },
        country(){
            return this.user?.country
        }
    },
    methods: {
        toggle() {
            this.open = !this.open
        },
        changeRegion(region){
            let formData = new FormData()
            formData.append('inp[country]', region)
            formData.append('id', cookies.get('token'))
            store.dispatch('auth/SaveUser', formData).then(() => {
                // store.dispatch('auth/GetUserData')
                window.location.reload()
            })
        },
        changeRole(role){
            let formData = new FormData()
            formData.append('inp[user_role]', role)
            formData.append('id', cookies.get('token'))
            store.dispatch('auth/SaveUser', formData).then(() => {
                store.dispatch('auth/GetUserData')
                if(role == 'service_provider'){
                    this.$router.push('/bussiness')
                } else {
                    this.$router.push('/homepage')
                }
                // window.location.reload()
            })
        }
    },
    data() {
        return {
            open: false,
            countries : [
                { slug: 'indonesia', name : 'Indonesia', 'flag': "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9f/Flag_of_Indonesia.svg/255px-Flag_of_Indonesia.svg.png"},
                { slug: 'singapore', name : 'Singapore', 'flag': "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Singapore.svg"},
                { slug: 'thailand', name : 'Thailand', 'flag': "https://cdn.britannica.com/38/4038-004-111388C2/Flag-Thailand.jpg"},
            ],
            roles: [
                { slug: 'service_provider', name: 'Service Provider', icon: 'ti ti-truck-delivery'},
                { slug: 'pet_owner', name: 'Pet Owner', icon: 'bx bxs-dog'},
            ]
        }
    },
}

</script>

<style lang="scss">
$--simple-drawer-bg-color : rgba(27, 98, 140, 1);
@import "~vue-simple-drawer/src/index";

</style>