const state = { 
    error: []
};

const mutations = { 
    ERROR(state, data) { 
        state.error = [{...data, isDisplayed : false}]
    }, 
    ERROR_DISPLAYED(state, index) {
        state.error[index].isDisplayed = true
    }
};

const getters = {
    getErrors(state){
        let error = state.error; 
        return error;
    }
}

const actions = {
    setDisplayedError({commit}, index){
        commit('ERROR_DISPLAYED', index)
    }
}

export default { 
    state, 
    getters,
    mutations, actions
}